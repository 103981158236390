<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'

export default {
  name: 'timedDraw',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmActivityDrawPrize/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          label: '',
          type: 'text',
          key: ''
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'signupStartTime',
          title: '报名时间',
          width: 100,
          align: 'left',
          onExport: (text, records) => {
            return text
              ? moment(text).format('YYYY.MM.DD HH:mm') +
                  '——' +
                  moment(records.signupEndTime).format('YYYY.MM.DD HH:mm')
              : ''
          },
          customRender: function(text, records) {
            return text
              ? moment(text).format('YYYY.MM.DD HH:mm') +
                  '——' +
                  moment(records.signupEndTime).format('YYYY.MM.DD HH:mm')
              : ''
          }
        },
        {
          dataIndex: 'signupCount',
          title: '报名数量',
          align: 'left'
        },
        {
          dataIndex: 'drawOpenTime',
          title: '开奖时间',
          align: 'left',
          customRender: function(text) {
            return text ? moment(text).format('YYYY.MM.DD HH:mm') : ''
          },
          onExport: function(text) {
            return text ? moment(text).format('YYYY.MM.DD HH:mm') : ''
          }
        },
        {
          dataIndex: 'winPrizeCount',
          title: '中奖数量',
          align: 'left'
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          width: 100,
          filters: [
            {
              text: '下架',
              value: '1'
            },
            {
              text: '上架',
              value: '0'
            }
          ],
          onExport: records => {
            return ['上架', '下架'][records]
          },
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          },
          filterMultiple: false
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                display: true,
                name: '中奖情况',
                onClick: () => {
                  this.$router.push('/marketingCenter/indexWinPrize?id=' + records.id)
                }
              },
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/farmActivityDrawPrize/${records.upDown == 0 ? 'batchDown' : 'batchUp'}`,
                    params: {
                      idList: [records.id]
                    }
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/marketingCenter/timedDrawDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmActivityDrawPrize/delBatch`,
                    params: { idList: [records.id] }
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/timedDrawDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.del.call(this, {
                  url: '/farmActivityDrawPrize/delBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/farmActivityDrawPrize/batchUp',
                  params: { idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/farmActivityDrawPrize/batchDown',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
